import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'


const postTemplate = ({data}) => {
  const post = data.markdownRemark
  return (
    <div>
      <Link to='/blog'>Go Back</Link>
      <hr />
      <h1>{post.frontmatter.title}</h1>
      <h4>Posted by {post.frontmatter.author} on {post.frontmatter.date}</h4>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </div>
  )
}

export default postTemplate

export const postQuery = graphql`
  query BlogPostQueryMatch($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        author
        date
      }
    }
  }
`